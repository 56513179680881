/* Importamos la fuente Montserrat */
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700&display=swap");

/* Aplica la fuente Montserrat a todos los componentes de Material-UI */
.MuiTypography-root {
  font-family: 'Montserrat';
}
/* Aplicamos el font a los div, h1, main, etc... */
:root {
  font-family: 'Montserrat';
}
 /* Limpiamos el body para que no tenga espacios */
body {
  margin: 0;
}

img[src="“https://ad.doubleclick.net/ddm/activity/src=10089018;type=invmedia;cat=mx_in000;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?”"]{
  display: none !important;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
